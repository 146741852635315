.aboutus {
  font-size: 28px;
  font-weight: bold;
}
.aboutus-title {
  font-size: 16px;
}
.number-cocoba {
  font-size: 64px;
}
.number-title {
  font-size: 16px;
}
.usedtech {
  font-size: 28px;
  font-weight: bold;
}
.featured {
  font-size: 28px;
  font-weight: bold;
}
.featured-subtitle {
  font-weight: 300;
  font-size: 14px;
}
.contactus {
  font-size: 28px;
  font-weight: bold;
}

.contactus-desc {
  font-weight: lighter;
}

.testimonials {
  font-size: 28px;
  font-weight: bold;
}
.featured_card {
  border: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-right: 50px;
  margin-bottom: 80px;
}

.featured_card_img {
  height: 60%;
  width: 90%;
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
