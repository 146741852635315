body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar_link {
  color: #000;
}

.navbar_glass {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease 0s;
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
}

.card_subtitle {
  color: #959595;
  font-size: 14px;
  font-weight: 500;
}

.title_card {
  font-size: 16px !important;
  font-weight: 600 !important;
}
