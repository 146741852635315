.footer-container {
  background-color: #959595;
}
.footer-body {
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 18px;
}
h5 {
  font-size: 20px;
  font-weight: bolder;
}
h6 {
font-size: medium;
font-weight: lighter;
}
.copyright{
  font-size: medium;
  font-weight: lighter;
}
