.animatedHeader {
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  color: #f35626;
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s infinite linear;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}
.projectCounter {
  background-color: #00000007;
}

.projectCounterContent {
  margin: 80px 0;
}

.why-us {
  margin: 80px 0;
}

.featured {
  margin: 80px 0;
}
.title-cocoba{
  font-size: 18px;
}